import { Component, Prop, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy';
import {
  ChartData,
  ChartOptions,
  Chart as ChartJs,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { COLOR_LIGHT } from '@/constants/color.constant';

ChartJs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
);

@Component({
  components: {
    Bar
  },
  name: 'StackedBarChart',
})
export default class StackedBarChart extends Vue {
  @Prop({
    required: true
  })
  chartData!: ChartData;

  @Prop({
    required: false,
    default: () => ({})
  })
  options?: ChartOptions;

  @Prop({
    required: false,
    default: () => 'var(--table-tr-bg)'
  })
  backgroundColor!: string;

  @Prop({
    type: Number,
    required: false,
    default: () => 476
  })
  height?: number;

  @Prop({
    type: Number,
    required: false,
  })
  width?: number;

  defaultOptions: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        align: 'center',
        labels: {
          usePointStyle: true,
          boxWidth: 9
        }
      },
      tooltip: {
        enabled: false,
      }
    },
    elements: {
      line: {
        fill: false,
        tension: 0
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: COLOR_LIGHT,
        },
        stacked: true
      },
      y: {
        grid: {
          display: false,
          color: COLOR_LIGHT,
        },
        beginAtZero: true,
        afterFit: () => 1050,
        stacked: true
      }
    }
  };

  getChart() {
    return (this.$refs.bar as any)?.getCurrentChart?.();
  }

  get chartOptions() {
    return {
      ...this.defaultOptions,
      ...this.options
    }
  }

  get styles() {
    return {
      backgroundColor: this.backgroundColor,
      width: '100%',
    }
  }

  get canvasWidth() {
    return this.width;
  }

  get canvasHeight() {
    return this.height;
  }
}
